import React, { useEffect, useState } from 'react';
import { Amplify,Auth, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';
import Home from "./components/Home";
import {Button,Container,Row,Col,Figure} from 'react-bootstrap'

Amplify.configure(awsconfig);

function App_2() {
  const [user, setUser] = useState(null);
  const [badgePhoto, setBadgePhoto] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });
    getUser().then(userData => {
      setUser(userData);
      if (userData) {
        setBadgePhoto(
          "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
            userData.signInUserSession.idToken.payload.identities[0].userId
        );
        setUserEmail(userData.signInUserSession.idToken.payload.email);
      }
    });
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }
  //<button onClick={() => Auth.signOut()}>Sign Out</button>
  //<p>User: {user ? JSON.stringify(user.attributes) : 'None'}</p>
  return (
    <div>
      <Container fluid="md">
        <Row>
          <br></br>
        </Row>
        <Row>
        <Col>
            {user ? (
              <Figure>
                <Figure.Image
                  width={50}
                  height={50}
                  alt="Image missing"
                  src={badgePhoto}
                />
                <Figure.Caption>Logged in as {userEmail}</Figure.Caption>
              </Figure>) : (<h4>Please Sign in to proceed.</h4>)}
        </Col>
          <Col>
            {user ? (
              <Button variant="outline-danger" onClick={() => Auth.signOut()}>Sign Out </Button>
            ) : (
              <Button variant="success" onClick={() => Auth.federatedSignIn()}>Sign In</Button>
            )}
          </Col>
        </Row>
      </Container>
      <hr></hr>
      {user ? (
        <Home user={user} badgePhoto={badgePhoto} userEmail={userEmail} />
      ) : (
        <h4>User not Authenticated by Amazon Federate SSO. </h4>
      )}
    </div>
  );
}

export default App_2;