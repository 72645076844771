import './App.css';
import {Storage} from '@aws-amplify/storage'
import React,{ useEffect,useState } from 'react';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Badge from 'react-bootstrap/Badge'
import Modal from 'react-bootstrap/Modal'
import Offcanvas from 'react-bootstrap/Offcanvas'

import { Amplify } from 'aws-amplify';

//import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';

Amplify.configure(awsExports);

/*
function processStorageList(results) {
  const filesystem = {}
  // https://stackoverflow.com/questions/44759750/how-can-i-create-a-nested-object-representation-of-a-folder-structure
  const add = (source, target, item) => {
    const elements = source.split("/");
    const element = elements.shift();
    if (!element) return // blank
    //item["url"] = App().downloadfile(elements)
    target[element] = target[element] || {__data: item } // element;
    if (elements.length) {
      target[element] = typeof target[element] === "object" ? target[element] : {};
      add(elements.join("/"), target[element], item);
    }
  };
  results.forEach(item => add(item.key, filesystem, item));
  //console.log(filesystem)
  //console.log(Object.entries(filesystem))
  return filesystem
}**/

function Home({ signOut, user, badgePhoto, userEmail }) {

  const [existingfiles, setExistingFiles] = useState({})
  const [dwnloadlink, setDwnLoadLink] = useState("");
  const [filedwnloadlink, setFileDwnLoadLink] = useState(""); 
  const [tobeuploadedfile, setToBeUploadedFile] = useState({})
  
  const [signedURL,setSignedURL] = useState("")
  const [downloadalertshow, setDownloadAlertChow] = useState(false);
  const [progressbar,setProgressBar] = useState(0);
  const [progressbararray,setProgressBarArray] = useState([]);
  const [filetobedeleted, setFileToBeDeleted] = useState(""); 
  const [uploadclicked, setUploadClicked] = useState(false);



  //for modal
  const [modalshow, setModalShow] = useState(false);
  const [modalmsg, setModalMsg] = useState("Welcome ")
  const handleModalClose = () => {
    delete_reset()
    setModalShow(false);
  }
  const handleModalShow = () => setModalShow(true);

  //for upload modal
  const [uploadmodalshow, setUploadModalShow] = useState(false);
  const [uploadmodalclosability, setUploadModalClosability] = useState(true);
  const handleuploadmodalClose = () => {
    if (uploadmodalclosability)
    {
      setUploadClicked(false)
      clearuploadqueue()
      setUploadModalShow(false);
    }
    
  }
  const handleuploadmodalShow = () => setUploadModalShow(true);

  //for alert modal

  const [alertmodalshow, setAlertModalShow] = useState(false);
  const [alertmodalmsgshow, setAlertModalmsgShow] = useState("");
  const [errmsg, setErrMsg] = useState("Welcome ")
  const alertmodalClose = () => setAlertModalShow(false)
  const alertmodalShow = () => setAlertModalShow(true);

  const processStorageList= (results) => {
    const filesystem = {}
    // https://stackoverflow.com/questions/44759750/how-can-i-create-a-nested-object-representation-of-a-folder-structure
    const add = (source, target, item) => {
      const elements = source.split("/");
      const element = elements.shift();
      if (!element) return // blank
      //Promise.resolve(downloadfile(elements)).then(function(value) {item["dwnlink"] = value;})
      
      target[element] = target[element] || {__data: item } // element;
      if (elements.length) {
        target[element] = typeof target[element] === "object" ? target[element] : {};
        add(elements.join("/"), target[element], item);
      }
    };
    results.forEach(item => add(item.key, filesystem, item));
    //console.log(Object.entries(filesystem))
    return filesystem
  }

  const readfiles = () =>{
    try{
      Storage.list('')// Object.keys(processStorageList(result))
      .then(result => setExistingFiles(processStorageList(result)))
      .catch(err => setErrMsg(err));
      //Object.keys(existingfiles).map((file,index) => downloadfile(file) 
      //&& (existingfiles[file]["dwnlink"]= dwnloadlink) )
      //setErrMsg("All files from Storage loaded")
      //Object.keys(existingfiles).map((file,index) => 
      
    }
    catch (error){ 
      console.log("Error uploading file: ", error);
      setErrMsg("Error reading files, here's the error message: " + error)
    }
  }

  const downloadfile = async (key) => {
    try{
      const url = await Storage.get(key, {
        download: false,
        expires: 600,
        progressCallback(progress) {console.log(`Downloaded: ${progress.loaded}/${progress.total}`);}
      })
      setModalMsg(key + " is ready for download. ")
      setDwnLoadLink(url)
      setFileDwnLoadLink(key)
      setDownloadAlertChow(true)
    }
    catch (error){ 
      console.log("Error uploading file: ", error);
      setErrMsg("Error getting the download link, here's the error message: " + error)
    }
    //console.log(url)
    //console.log(dwnloadlink)
    //setDwnLoadLink(values => ({...values, ["key"]: key, ["url"]: url}))
    //return url
  }

  const deletefile = async (key) => {
    try{
      const deletestrkey = await Storage.remove(key)
      setErrMsg(key+" Successfully deleted")
      handleModalClose()
      readfiles()
    }
    catch (error){ 
      console.log("Error uploading file: ", error);
      setErrMsg("Error deleting file, here's the error message: " + error)
    }
    
  }

  const fileuploadfunction = async (toupfilezz) => {
    //setErrMsg("Loading "+ files.name + " please wait")
    const filesuploaded = ""
    //console.log(tobeuploadedfile)
    //setToBeUploadedFile({...this.tobeuplaodedfileload.name, name: 'someothername'});
    //for (let file of tobeuploadedfile) {
      //console.log("Do something with " + file.name)
      //Array.from(tobeuplaodedfileload).forEach(file => setToBeUploadedFile({...file, ["key"]: 'key', ["url"]: 'url'}))
      //setToBeUploadedFile(values => (console.log(values.name)))
    //}
    //console.log(tobeuploadedfile)
    //setToBeUploadedFile(prevstat => prevstat, {namesss:"123123"})
    //Object.keys(tobeuplaodedfileload).map((file,index) => setToBeUploadedFile(prevstat => prevstat[file], {["namesss"]:"123123"} ) && console.log(file,index, tobeuplaodedfileload[file].name))
    //console.log(tobeuplaodedfileload)
    const uploadedkeystring = ""
    console.log(toupfilezz)
    console.log(tobeuploadedfile)
    setUploadClicked(true)
    Array.from(toupfilezz).forEach(async file =>{ uploadfile(file)})
    //return await Promise.all(toupfilezz.map(this.uploadfile))
    //Promise.resolve(downloadfile(elements)).then(function(value) {item["dwnlink"] = value;})
    //console.log(filesuploaded)
    //setErrMsg("These files have been uplaoded successfully "+ filesuploaded)
    //clearuploadqueue()
    //Array.from(files).forEach(file => setToBeUploadedFile(files))
    //console.log(tobeuploadedfile)
    //console.log(existingfiles)
  }

  const clearuploadqueue = async () => {
    console.log("clearuploadqueue")
    setToBeUploadedFile({})
    setProgressBarArray([])
  }

  const setuploadqueue = async (files) => {
    console.log(files)
    if (files.length === 0)
    {
      console.log("zero")
      return
    }
    var nameexists = false
    Object.values(files).map((v,i)=>{
                                console.log(Object.keys(existingfiles).includes(v.name))
                                if(Object.keys(existingfiles).includes(v.name))
                                {
                                  setAlertModalmsgShow("A file with the name "+v.name+" exists in the S3 bucket. S3 bucket does not accept duplicate names. Please change the name and try again.")
                                  alertmodalShow()
                                  nameexists = true  
                                }
                                setProgressBarArray(values => ({...values, [v.name+"progressbar"]:0,
                                                                           [v.name+"status"]:"Pending"}))
                            })
    
                            
    if(nameexists !==true)
    {
      handleuploadmodalShow()
      setToBeUploadedFile(files)
    }                        
    //console.log(tobeuploadedfile)
    //setToBeUploadedFile(values => ({...values, ["progressbar"]:"1"}))
  }

  const uploadfile = async (toupfile) => {
      try {
        setErrMsg("Loading "+ toupfile.name + " please wait")
        //console.log(progressbararray)
        const key = await Storage.put(toupfile.name, toupfile, {
            contentType: toupfile.type, // contentType is optional
            metadata: { key: "ATVA-frontend"},
            progressCallback(progress) {
              setProgressBar((progress.loaded/progress.total)*100)
              setProgressBarArray({[toupfile.name+"progressbar"]:(progress.loaded/progress.total)*100})
              setProgressBarArray({[toupfile.name+"status"]:"Uploading"})
              //console.log(toupfile.name);
              //console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
              setErrMsg("Uploading " + toupfile.name)
              setUploadModalClosability(false)
            },
          }
        ).then((result)=>{
          //console.log(result)
          setProgressBarArray({[toupfile.name+"status"]:"Complete"})
          setErrMsg('Completed upload on ' + result.key)
        });
        setUploadModalClosability(true)
        setProgressBar(0)
        //clearuploadqueue()
        readfiles()
        return "Success"
        
      } catch (error) {
        console.log("Error uploading file: ", error);
        setErrMsg("Error uploading file: ")
        return error
      }
  }
  const downloadreset = async()=>{
    setDwnLoadLink("")
    setFileDwnLoadLink("")
    setDownloadAlertChow(false)
  }
 
  //Function to handle the button clicks
  function download_onclick(e) {
    console.log("download", e.target.value)
    downloadfile(e.target.value)

  }
  function delete_onclick(e) {
    handleModalShow()
    setModalMsg("You are about to delete " + e.target.value + ". Do you wish to proceed")
    setFileToBeDeleted(e.target.value)
    console.log("delete", e.target.value)
    
  }
  const delete_reset = async() =>{
    setFileToBeDeleted("")
  }

  useEffect(() => {readfiles()}, [])
  
/*<Modal.link href={dwnloadlink}>Click here to download</Modal.link>
<Alert variant='info' show ={downloadalertshow} onClose={() => downloadreset()}  dismissible>
        <Alert.Heading>{filedwnloadlink} is ready for download.</Alert.Heading>
        <Alert.Link href={dwnloadlink}>Click here</Alert.Link>
      </Alert>
      <Alert variant='warning'>{errmsg}</Alert>
*/
  return (
    <div className="App">
      <div className="container">
       <Modal
          show={alertmodalshow}
          onHide={alertmodalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>{alertmodalmsgshow}</Modal.Body>
      </Modal>
      

      <Modal
          show={downloadalertshow}
          onHide={downloadreset}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Download box</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalmsg} Right click on this <a href={dwnloadlink} >link </a> and choose save as to download the file.
          </Modal.Body>
      </Modal>
      
      <Modal
          show={modalshow}
          onHide={handleModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation box</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalmsg}</Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={() =>deletefile(filetobedeleted)}>Yes</Button>
            <Button variant="outline-secondary" onClick={handleModalClose}>No</Button>
          </Modal.Footer>
      </Modal>

     

      <Container fluid="md">
        <Row>
          
        </Row>
      </Container>
      <Container fluid="md">
        <Row>
          <input type='file' accept='' multiple onChange={(e) => setuploadqueue(e.target.files)} onClick={(e) => e.target.value = ''}/>
        </Row>
        
      </Container>
      <Modal
          show={uploadmodalshow}
          onHide={handleuploadmodalClose}
          backdrop="static"
          keyboard={false}
          size = "lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload box</Modal.Title>
          </Modal.Header>
          <Modal.Body> <p>
              Files ready for upload to the Storage. 
              Click Upload and wait for all files to be completed before closing this dialog box.
            </p>
            <Table responsive = "sm" size="sm">
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Size</td>
                  <td>Type</td>
                  <td>Progress</td>
                  <td>Status</td>
                </tr>
              </thead>
                <tbody>
                  {Object.keys(tobeuploadedfile).map((file,index) =>
                    <tr key={index}>
                      <td>{tobeuploadedfile[file].name}</td>
                      <td>{(tobeuploadedfile[file].size)/1000000000} gb</td>
                      <td>{tobeuploadedfile[file].type}</td>
                      <td><ProgressBar animated striped variant="success" now={progressbararray[tobeuploadedfile[file].name+"progressbar"]} /></td>
                      <td>{progressbararray[tobeuploadedfile[file].name+"status"]}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              {uploadclicked?<p></p>:<Button variant="outline-success" size="sm" onClick = {()=> fileuploadfunction(tobeuploadedfile)}>Upload</Button>}
            </Row>
          </Modal.Footer>
      </Modal>

        
      <hr></hr>
      
      <h6>
        Files in <Badge bg="secondary">S3</Badge> Storage.
      </h6>
      <Table responsive = "md" size="md">
            <thead>
                <tr>
                  <td>Index</td>
                  <td>Name</td>
                  <td>Size</td>
                  <td>Delete</td>
                  <td>Download</td>
                </tr>
            </thead>
            <tbody>
              {Object.keys(existingfiles).map((file,index) => 
                <tr key={index}>
                  <td>{index}</td>
                  <td>{file}</td>
                  <td>{((existingfiles[file].__data.size)/1000000000)} gb</td>
                  <td><Button variant="secondary" onClick = {delete_onclick} value = {file} >Delete</Button></td>
                  <td><Button variant="secondary" onClick = {download_onclick} value = {file} >Load Download</Button></td>
                </tr>
              )}
            </tbody>
      </Table>
      </div>
    </div>
    
  );
}

export default Home
