/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0b6e0f62-4145-4f21-9950-a297e164b12b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_LpbSdMJOY",
    "aws_user_pools_web_client_id": "1bfqr98hqvkb0kjmfurvfu5kid",
    "oauth": {
        "domain": "g4s1yb3yok2k-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://media-analysis.mechatronics.amazon.dev/",
        "redirectSignOut": "https://media-analysis.mechatronics.amazon.dev/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "AMAZON"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "map-wepage-host-staging",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2h3jik1j6xwt4.cloudfront.net",
    "aws_user_files_s3_bucket": "atmap-webpage-storage185329-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
